/**
 * @title 新建应用系统
 * @author wwj
 */

import React from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";

const OperationCenterForm = Loader.loadBusinessComponent("OperationCenterForm");

@withRouter
@Decorator.businessProvider("tab")
class ModuleView extends React.Component {
  submit = values => {
    let { tab, location } = this.props;
    Service.operation
      .addOperationCenter(values)
      .then(res => {
        message.success("添加成功！请前去配置系统模块");
        SocketEmitter.emit(SocketEmitter.eventName.addAppManagement, {
          res: res,
          add: true
        });
          tab.closeCurrentTab({ action: "push" });
          tab.closeTabForName('appManagementDetail');
          tab.goPage({
            moduleName:'appManagementDetail',
            location,
            data: { id: res.data.id, type: 2 }
          });
      })
      .catch(err => {
        message.error(err.data.message);
      });
  };
  render() {
    return (
      <div className="operation-container" style={{ padding: "20px 0 50px 0" }}>
        <OperationCenterForm submit={this.submit} type="add" />
      </div>
    );
  }
}

export default ModuleView;
