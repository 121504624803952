(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("ReactRouterDOM"));
	else if(typeof define === 'function' && define.amd)
		define("appManagementNew", ["React", "antd", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["appManagementNew"] = factory(require("React"), require("antd"), require("ReactRouterDOM"));
	else
		root["appManagementNew"] = factory(root["React"], root["antd"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 